@import 'settings/variables';
@import '~bootstrap/scss/bootstrap.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto');

#root {
  height: 100%;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html, body {
  background-color: $background-base-color;
  font-family: $font-family;
  height: 100%;
  color: $dark-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a { 
    text-decoration: none; } 

  *:focus {
    outline: none;
  }
}

p, ul, label {
  margin: 0;
  padding: 0;
}

@keyframes hint-pulse {
  0% {
    box-shadow: 0 0 0 0 RGBA(var(--base-rgb), 0.4),
      0 0 0 0 RGBA(var(--base-rgb), 0);
  }
  25% {
    box-shadow: 0 0 0 7px RGBA(var(--base-rgb), 0.2),
      0 0 0 0 RGBA(var(--base-rgb), 0.4);
  }
  75% {
    box-shadow: 0 0 0 14px RGBA(var(--base-rgb), 0),
      0 0 0 7px RGBA(var(--base-rgb), 0.2);
  }
  100% {
    box-shadow: 0 0 0 0 RGBA(var(--base-rgb), 0),
      0 0 0 14px RGBA(var(--base-rgb), 0);
  }
}

.hint-beacon {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: RGB(var(--base-rgb));
  border-radius: 50%;
  animation: hint-pulse linear 2s infinite;
}

.__floater:not(.__floater__open) {
  pointer-events: none;
}

.__floater__container > button > svg {
  display: none;
}

.Toastify__toast-container {
  z-index: 999999;

  .toast-custom-container {
    background-color: white;
    display: flex;
    align-items: flex-start;
    border-radius: 5px;
    top: 10rem;
    font-family: 'Roboto', sans-serif;

    &.success {
      border-left: 6px solid #5BB500;
    }

    &.warning {
      border-left: 6px solid #FFA011;
    }

    &.error {
      border-left: 6px solid #FC3D3D;
    }
  }
} 

