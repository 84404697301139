.loader-wrapper {
  height: 100%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 100%;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .spinner {
    animation: spinner .5s linear infinite;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-right-color: transparent !important;
    width: 30px;
    height: 30px;

    &.small {
      width: 15px;
      height: 15px;
    }

    &.large {
      width: 45px;
      height: 45px;
    }
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}